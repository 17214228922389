mapboxgl.accessToken = 'pk.eyJ1IjoidGlic2Fyc29mdHdhcmUiLCJhIjoiY2w3Z2l5aTQ5MDR6YjN2cXB1dGE5bW11YSJ9.-PTYaz73ycQdUPC6CZDEIg';

const map = new mapboxgl.Map({
    container: 'heatmap',
    style: 'mapbox://styles/tibsarsoftware/cl7f34duw001514nolthflsy5',
    center: [ -96.4247, 40.51073],
    zoom: 3,
    projection: 'globe',
    maxZoom: 9,
});

map.addControl(new mapboxgl.NavigationControl(), 'top-left');
 
map.on('style.load', () => {
map.setFog({});
});
 
map.on('load', () => {

    var pinkMarker = document.getElementById(`map-data`).dataset.mapMarkerPink;


    map.loadImage(
        pinkMarker,
        (error, image) => {
        if (error) throw error;
        map.addImage('map-marker-pink', image);
        });

    var purpleMarker = document.getElementById(`map-data`).dataset.mapMarkerPurple;


    map.loadImage(
        purpleMarker,
        (error, image) => {
        if (error) throw error;
        map.addImage('map-marker-purple', image);
        });
    
    var partnerData = JSON.parse(document.getElementById(`map-data`).dataset.partner);
    map.addSource('partners', {
        type: 'geojson',
        tolerance: 0,
        data: {
            "type": "FeatureCollection",
            "features": partnerData,
        }
    });

    map.addLayer({
        'id': 'partners-point',
        'type': 'symbol',
        'source': 'partners',
        'layout': {
            'visibility': 'visible',
            'icon-image': 'map-marker-purple',
            'icon-allow-overlap': true
        },


        }
    );

var registryData = JSON.parse(document.getElementById(`map-data`).dataset.registry);
    map.addSource('registries', {
        type: 'geojson',
        tolerance: 0,
        data: {
            "type": "FeatureCollection",
            "features": registryData,
        }
    });

    map.addLayer({
        'id': 'registries-point',
        'type': 'symbol',
        'source': 'registries',
        'layout': {
            'visibility': 'visible',
            'icon-image': 'map-marker-pink',
            'icon-allow-overlap': true
        },

        }
    );
});

// After the last frame rendered before the map enters an "idle" state.
map.on('idle', () => {
    // If these two layers were not added to the map, abort
    if (!map.getLayer('partners-point') || !map.getLayer('registries-point')) {
    return;
    }
     
    // Enumerate ids of the layers.
    const toggleableLayerIds = ['partners-point', 'registries-point'];
    const toggleableLayerNames = ['Partners', 'Registrants']
     
    // Set up the corresponding toggle button for each layer.
    var index = 0;
    for (const id of toggleableLayerIds) {
    // Skip layers that already have a button set up.
    if (document.getElementById(id)) {
    continue;
    }
     
    // Create a link.
    const link = document.createElement('a');
    link.id = id;
    link.href = '#';
    link.textContent = toggleableLayerNames[index];
    link.className = 'active';

    index += 1;
     
    // Show or hide layer when the toggle is clicked.
    link.onclick = function (e) {
    const clickedLayer = this.id;
    e.preventDefault();
    e.stopPropagation();
     
    const visibility = map.getLayoutProperty(
    clickedLayer,
    'visibility'
    );
     
    // Toggle layer visibility by changing the layout object's visibility property.
    if (visibility === 'visible') {
    map.setLayoutProperty(clickedLayer, 'visibility', 'none');
    this.className = '';
    } else {
    this.className = 'active';
    map.setLayoutProperty(
    clickedLayer,
    'visibility',
    'visible'
    );
    }
    };
     
    const layers = document.getElementById('heatmap-menu');
    layers.appendChild(link);
    }
    });
